import { MetaSwiper, SafariSwiper, getPosition } from "./meta-settings.js";
import Swiper from "./plugins/swiper-bundle.esm.browser.min.js";

AOS.init({
	startEvent: "load",
	disableMutationObserver: false,
	duration: 600,
	once: true,
});
AOS.refresh(true);

// First we get the viewport height and we multiple it by 1% to get a value for a vh unit
let vh = window.innerHeight * 0.01;
// Then we set the value in the --vh custom property to the root of the document
document.documentElement.style.setProperty("--vh", `${vh}px`);

// We listen to the resize event
window.addEventListener("resize", () => {
	// We execute the same script as before
	vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty("--vh", `${vh}px`);
	if(document.getElementsByClassName("header")[0]) {
		document.documentElement.style.setProperty(
			"--header-height",
			`${
				document.getElementsByClassName("header")[0].getBoundingClientRect()
					.height
			}px`
		);
	}
});

const header = document.getElementsByClassName("header")[0];
// Header scroll
const scrollContainer = () => {
	return window.pageYOffset !== undefined
		? window.pageYOffset
		: (document.documentElement || document.body.parentNode || document.body)
				.scrollTop;
};

if (header && header.classList.contains("fixed")) {
	document.addEventListener("scroll", () => {
		if (scrollContainer() > 0) {
			header.classList.add("scrolled");
		} else if (scrollContainer() == 0) {
			header.classList.remove("scrolled");
		}
	});
}

// menu handlers

///check mobile menu show/hide condition
const mobileMenuStartPoint = +getComputedStyle(
	document.documentElement
).getPropertyValue("--mobile-menu-start-point");
let isMobileMenuEnable =
	$(window).outerWidth() <= mobileMenuStartPoint || $(".header-mobile").length;

$(".dropdown-toggle").click(function (e) {
	if (isMobileMenuEnable) {
		//close all opened sub menu
		$(".menu-item.dropdown.active .dropdown-menu").slideUp({
			complete: function () {
				$(this).closest(".dropdown").removeClass("active");
			},
		});

		//open current submenu
		$(this).closest(".menu-item.dropdown").toggleClass("active");
		if ($(this).closest(".menu-item.dropdown").hasClass("active")) {
			e.preventDefault();
			$(this).next(".dropdown-menu").slideDown();
		}
	}
});

// toggle menu handler
function menuToggle() {
	$(".menu-toggle").toggleClass("active");
	$(".navbar-nav").toggleClass("active");
	$(".header-close-wrapper").toggleClass("active");
	// LockScroll when burger open and enable when closed and enable scroll on menu
	scrollLock.getScrollState()
		? scrollLock.disablePageScroll(document.querySelector(".navbar-nav .menu"))
		: scrollLock.enablePageScroll();
}
//menu update function
function updateMenu() {
	isMobileMenuEnable =
		$(window).outerWidth() <= mobileMenuStartPoint ||
		$(".mobile-header").length;
	if (!isMobileMenuEnable) {
		$(".dropdown-menu").css("display", "");
		$(".header-close-wrapper").removeClass("active");
		$(".menu-item.active").removeClass("active");
		$(".navbar-nav").removeClass("active");
		$(".menu-toggle").removeClass("active");
		// LockScroll when burger open and enable when closed
		scrollLock.enablePageScroll();
	}
}
$(window).on("resize orientationchange", updateMenu);
// end of toggle menu handler

$(".menu-toggle").click(menuToggle); //menu toggles
$(".header-close-wrapper").click(menuToggle); //menu toggles

// Tesla style menu
const navbar = document.getElementsByClassName(`navbar-nav`)[0];

if (navbar && window.innerWidth >= 850) {
	const navbarElements = navbar.querySelectorAll(`a`);
	const spanElement = navbar.querySelector(`span`);
	const activeMenuElement = navbar.querySelector(`a.active`);

	function backgroundMenuPositionFunc(targetElement, flagMouseEnter) {
		const navbarPosition = navbar.getBoundingClientRect();
		const elementPosition = targetElement.getBoundingClientRect();

		let spanPositionLeftStart = elementPosition.left - navbarPosition.left;
		let spanWidthStart = elementPosition.width;

		if (flagMouseEnter) {
			spanElement.style.setProperty(
				"--span-transition",
				`0.5s cubic-bezier(0.75, 0, 0, 1), border-radius 0.5s ease`
			);
		} else {
			spanElement.style.setProperty(
				"--span-transition",
				`opacity 0.5s ease, visibility 0s 0s, border-radius 0.5s ease`
			);
		}
		spanElement.style.setProperty("--width-span", `${spanWidthStart}px`);
		spanElement.style.setProperty(
			"--left-position-span",
			`${spanPositionLeftStart}px`
		);
	}

	if (activeMenuElement) {
		backgroundMenuPositionFunc(activeMenuElement, true);
		spanElement.classList.add("active");

		navbarElements.forEach((elem) => {
			elem.addEventListener("mouseenter", function (e) {
				backgroundMenuPositionFunc(e.target, true);
			});

			navbar.addEventListener("mouseleave", function (e) {
				backgroundMenuPositionFunc(activeMenuElement, true);
			});
		});
	} else {
		let flagMouseEnter = false;

		navbarElements.forEach((elem) => {
			elem.addEventListener("mouseenter", function (e) {
				backgroundMenuPositionFunc(e.target, flagMouseEnter);
				spanElement.classList.add("active");

				flagMouseEnter = true;
			});
		});

		navbar.addEventListener("mouseleave", function (e) {
			spanElement.classList.remove("active");
			flagMouseEnter = false;
			spanElement.style.setProperty(
				"--span-transition",
				`opacity 0.5s ease, visibility 0s 0.5s, border-radius 0.5s ease`
			);
		});
	}
}

let choicesAllArr = [];
const defaultSelectArr = document.querySelectorAll('.default-select');

function selectsLogic() {
	const choicesDOM = document.querySelector(["[data-choices]"]);
	if (choicesDOM) {
		const choicesArr = document.querySelectorAll(["[data-choices]"]);
		for (let i = 0; i < choicesArr.length; i++) {
			const parentContainer = choicesArr[i].parentNode;
			const originalElement =  parentContainer;
			const clonedElement = originalElement.cloneNode(true);

			/*console.log(parentContainer);*/

			/*const list = parentContainer.querySelector(".default-select__list");*/

/*			console.log(choicesArr);*/

			if(!parentContainer.classList.contains('choices__inner')) {

				let allOptions = [];
				choicesArr[i].querySelectorAll('option').forEach(option => {
					allOptions.push(option);
				});

				let isOpen = false;

				/*const elementToWrap = choicesArr[i];

				const wrapperDiv = document.createElement('div');

				wrapperDiv.classList.add('w-full', 'choices-container');

				elementToWrap.parentNode.insertBefore(wrapperDiv, elementToWrap);

				wrapperDiv.appendChild(elementToWrap);*/

				const choices = new Choices(choicesArr[i], {
					searchEnabled: true,
					itemSelectText: "",
					placeholder: true,
					searchPlaceholderValue: "Search",
					shouldSort: false,
					classNames: {
						flippedState: '',
					},
				});

				const toggleSpan = document.createElement('span');
				toggleSpan.classList.add('choices-toggle');

				parentContainer.appendChild(toggleSpan);

				/*console.log(choices);*/

				if(!parentContainer.classList.contains('sort-select')) {
					choicesAllArr.push(choices)
				}

				const choicesMultipleElement = parentContainer.querySelector(
						".choices[data-type='select-multiple']"
				);

				if(!choicesMultipleElement) {
					toggleSpan.style.display = 'none';
				} else {
					/*choices.containerOuter.element.addEventListener('showDropdown', function () {
						isOpen = true;
					});

					choices.containerOuter.element.addEventListener('hideDropdown', function () {
						isOpen = false;
					});*/

					if (toggleSpan) {
						toggleSpan.addEventListener("click", () => {
							if (parentContainer.querySelector(".is-open")) {
								choices.hideDropdown();
							} else {
								choices.showDropdown();
							}
						});
					}
				}

				/*if (window.innerWidth >= 1025) {
					if (choicesMultipleElement) {
						choicesMultipleElement.addEventListener("click", () => {
							if (parentContainer.querySelector(".is-open")) {
								choices.hideDropdown();
							}
						});
					}
				} else {
					if (choicesMultipleElement) {
						choicesMultipleElement.addEventListener("click", (event) => {
							if (parentContainer.querySelector(".is-open")) {
								choices.hideDropdown();
								event.preventDefault();
							}
						});
					}
				}*/

				/* New multiselect logic */
				if (
					choicesArr[i].multiple &&
					choicesArr[i].hasAttribute("data-multiple-list-logic")
				) {
					let optionName = null;
					let optionValue = null;

					let selectAllFlag = false;

					const multiplePlaceholder = parentContainer.querySelector(
						".choices__list--multiple"
					);

					const list = document.createElement("ul");
					list.className = "default-select__list";
					parentContainer.appendChild(list);

					function createListItem(optionName, optionValue) {
						if(!clonedElement.classList.contains('add-select-all')) {

							if(clonedElement.classList.contains('is-select-all')) {
								clonedElement.classList.add('add-select-all');
								const listItem = document.createElement("li");
								listItem.setAttribute("data-val", 'All');
								listItem.innerHTML = `Select All`;
								listItem.classList.add("default-select__list-item");
								list.appendChild(listItem);

								listItem.addEventListener("click", () => {
									const listArr = parentContainer.querySelectorAll('.default-select__list');

									if(listArr.length) {
										for (let i = 0; i < listArr.length; i++) {
											listArr[i].remove();
										}
										list.remove();
									}
									clonedElement.classList.remove('add-select-all');
									clonedElement.classList.remove('is-select-all');

									/*choices.removeActiveItems();*/
									choices.destroy();
									originalElement.parentNode.replaceChild(clonedElement, originalElement);
									const options = clonedElement.querySelectorAll('option');
									/*console.log(options)*/

									for (let i = 0; i < options.length; i++) {
										/*if(options[i].value === 'All') {
											options[i].remove();
										}*/
										options[i].removeAttribute('selected');
									}
									updateLogic();
								});
							} else {
								const listItem = document.createElement("li");
								listItem.setAttribute("data-val", optionValue);
								listItem.innerHTML = `${optionName}`;
								listItem.classList.add("default-select__list-item");
								list.appendChild(listItem);

								listItem.addEventListener("click", () => {
									handleListItemClick(listItem);
								});
							}
						}
					}

					function handleSelectedOptions() {
						list.innerHTML = "";

						const selectedOptions = Array.from(choicesArr[i].selectedOptions);

						if (selectedOptions.length >= 1) {
							list.classList.add("is-visible");
						} else {
							list.classList.remove("is-visible");
						}

						if(selectedOptions.length === 1) {
							for (let j = 0; j < selectedOptions.length; j++) {
								if (selectedOptions[j].textContent === 'Select') {
									list.classList.remove("is-visible");
								}
							}
						}

						if(selectedOptions.length === 2) {
							for (let j = 0; j < selectedOptions.length; j++) {
								if (selectedOptions[j].textContent === 'Select All') {
									list.classList.remove("is-visible");
								}
							}
						}

						if (selectedOptions.length === 0 && !choicesArr[i].multiple) {
							choices.setChoiceByValue("");
						}

						selectedOptions.forEach(function (option) {
							optionName = option.textContent;
							optionValue = option.value;
							if (optionName !== "Select" && optionName !== "Select All") {
								createListItem(optionName, optionValue);
							}

						});

						const listArr = list.querySelectorAll(".default-select__list-item");
						if (listArr.length === 1) {
							multiplePlaceholder.textContent = optionName.replace(/&amp;/g, "&");
						} else if (listArr.length >= 2) {
							multiplePlaceholder.textContent = `Selected ${listArr.length} items`;
						} else {
							multiplePlaceholder.textContent = "Select";
						}

						if(clonedElement.classList.contains('add-select-all')) {
							multiplePlaceholder.textContent = `Selected All items`;
						}
					}

					function handleAllOptions() {
						if(!choicesArr[i].classList.contains('is-select-all')) {

							const selectedOptions = Array.from(choicesArr[i].selectedOptions);

							selectedOptions.forEach(function (option) {
								optionName = option.textContent;
								optionValue = option.value;

								if(optionName === "Select All") {
									const listArr = parentContainer.querySelectorAll('.default-select__list');

									if(listArr.length) {
										for (let i = 0; i < listArr.length; i++) {
											listArr[i].remove();
										}
										list.remove();
									}
									clonedElement.classList.add('is-select-all');

									choices.destroy();
									originalElement.parentNode.replaceChild(clonedElement, originalElement);
									const options = clonedElement.querySelectorAll('option');
									/*console.log(options)*/

									for (let i = 0; i < options.length; i++) {
										options[i].setAttribute('selected', 'selected');
									}
									choicesArr[i].classList.add('is-select-all');
									updateLogic();
								}
							});
						}

						const listArr = list.querySelectorAll(".default-select__list-item");
						if (listArr.length === 1) {
							multiplePlaceholder.textContent = optionName.replace(/&amp;/g, "&");
						} else if (listArr.length >= 2) {
							multiplePlaceholder.textContent = `Selected ${listArr.length} items`;
						} else {
							multiplePlaceholder.textContent = "Select";
						}
					}

					function handleListItemClick(listItem) {
						const optionValue = listItem.getAttribute("data-val");

						choices.removeActiveItemsByValue(optionValue);
						handleSelectedOptions();

						if(document.querySelector('.first-form-container')) {
							if (choicesArr[i].getAttribute("id") === "all-departments") {
								load('jobs/roles_process', 'sectors#all-departments');
							}

							if (choicesArr[i].getAttribute("id") === "state") {
								load('jobs/markets_process', 'states#state');
							}
						}

						if(document.querySelector('#alerts-update-loads')) {
							if (choicesArr[i].getAttribute("id") === "all-departments") {
								load('profile/roles_process', 'sectors#all-departments');
							}

							if (choicesArr[i].getAttribute("id") === "state") {
								load('profile/markets_process', 'states#state');
							}
						}

						if(document.querySelector('#alerts-update-loads-live')) {
							if (choicesArr[i].getAttribute("id") === "all-departments") {
								load('profile_live/roles_process', 'sectors#all-departments');
							}

							if (choicesArr[i].getAttribute("id") === "state") {
								load('profile_live/markets_process', 'states#state');
							}
						}
					}

					handleSelectedOptions();

					choicesArr[i].addEventListener("change", function () {
						handleSelectedOptions();
						handleAllOptions();
					});

					list.addEventListener("click", function (event) {
						const liElement = event.target.closest(".default-select__list-item");
						if (liElement) {
							handleListItemClick(liElement);
						}

						const selectedOptions = Array.from(choicesArr[i].selectedOptions);

						/*console.log(selectedOptions)*/

						/*if (selectedOptions.length >= 1) {
							list.classList.add("is-visible");
						} else {
							list.classList.remove("is-visible");
						}
*/
						if(selectedOptions.length === 1 || selectedOptions.length === 2) {
							/*console.log(1234)*/
							for (let j = 0; j < selectedOptions.length; j++) {
								if (selectedOptions[j].textContent === 'Select All') {
									choices.destroy();
									list.classList.remove("is-visible");
									selectsLogic();
								}
							}
						}


					});
				}
			}

		}
	}
}
selectsLogic();

const btnClear = document.querySelector('.btn-clear');

if(btnClear) {
	const parentForm = btnClear.parentNode;
	const inputs = parentForm.querySelectorAll('input');
	btnClear.addEventListener('click', () => {
		const choicesArr = document.querySelectorAll(["[data-choices]"]);

		inputs.forEach(input => {
			input.value = "";
		})

		const listArr = parentForm.querySelectorAll('.default-select__list');

		if(listArr.length) {
			for (let i = 0; i < listArr.length; i++) {
				listArr[i].remove();
			}
		}

		for (let i = 0; i < choicesArr.length; i++) {
			choicesArr[i].classList.remove('is-select-all');
		}

		choicesAllArr.forEach(choices => {
			choices.destroy();
		});
		choicesAllArr = [];

		selectsLogic();
		load('jobs/search', 'form:#search_form', 'sort#sort', 'scroll=false'); return false;
	})
}

window.alertClear = function alertClear() {
	if(document.querySelector('.alert-clear-btn')) {
		const alertClearBtnArr = document.querySelectorAll('.alert-clear-btn');

		/*console.log(alertClearBtnArr);*/

		alertClearBtnArr.forEach((btn, i) => {
			if(!btn.classList.contains('is-start')) {
				btn.addEventListener('click', () => {
					const parentContainer = btn.parentNode.parentNode;

					const choicesArr = parentContainer.querySelectorAll(["[data-choices]"]);

					const listArr = parentContainer.querySelectorAll('.default-select__list');

					if(listArr.length) {
						for (let i = 0; i < listArr.length; i++) {
							listArr[i].remove();
						}
					}

					for (let i = 0; i < choicesArr.length; i++) {
						choicesArr[i].classList.remove('is-select-all');
					}

					choicesAllArr.forEach(choices => {
						choices.destroy();
					});
					choicesAllArr = [];

					selectsLogic();
				})
			}
			btn.classList.add('is-start');
		})
	}
}
alertClear();

if (document.getElementsByClassName('job-card')[0]) {
	const jobCards = document.querySelectorAll('.job-card');
	jobCards.forEach(card => {
		const statusType = card.querySelector('.status-type');
		if (statusType) {
			statusType.addEventListener('click', (event) => {
				event.preventDefault();
			});
		}
	});
}

const jobsSlider = document.getElementsByClassName("jobs-slider")[0];

if (jobsSlider) {
	const gallerySwiper = new Swiper(".jobs-slider", {
		slidesPerView: 1,
		spaceBetween: 20,
		threshold: 10,
		autoplay: {
			delay: 3500,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		effect: "creative",
		creativeEffect: {
			prev: {
				translate: ["-20%", 0, -500],
				opacity: 0,
			},
			next: {
				translate: ["20%", 0, -600],
				opacity: 0,
			},
		},
		pagination: {
			el: ".jobs-swiper-pagination",
			type: "bullets",
			clickable: true,
		},
	});
}

const testimonialsSlider = document.getElementsByClassName(
	"testimonials-slider"
)[0];

if (testimonialsSlider) {
	const testimonialsSwiper = new Swiper(".testimonials-slider", {
		slidesPerView: 1,
		spaceBetween: 20,
		threshold: 10,
		autoplay: {
			delay: 3500,
			disableOnInteraction: false,
			pauseOnMouseEnter: true,
		},
		effect: "creative",
		creativeEffect: {
			prev: {
				translate: ["-25%", 0, -500],
				opacity: 0,
			},
			next: {
				translate: ["25%", 0, -600],
				opacity: 0,
			},
		},
	});
}

/**
 * @function for floating sidebar
 * */
const contentSticky = document.getElementById("content-sticky");
const rightSidebar = document.getElementById("sidebar");
if (rightSidebar && window.innerWidth >= 902) {
	const sidebarBuffer = rightSidebar.querySelector(".sidebar__buffer");
	const sidebarInner = rightSidebar.querySelector(".sidebar__inner");
	// const headerHeight = +getRootStyle("--header-height").replace("px", "");
	const headerHeight = 0;

	// gap on top and bottom of sticky sidebar
	const gap = 64;

	let lastScrollTop = 0;

	// if flag true === scrolling down else scrolling up
	let flag = null;

	// if flagHeight true === sidebar is higher than viewport height
	let flagHeight =
		document.querySelector(".sidebar__inner").getBoundingClientRect().height >
		window.innerHeight - headerHeight;

	function calcHeightOfSidebar(flag) {
		const contentStickyTop = getPosition(contentSticky).y;
		const sidebarInnerTop = getPosition(sidebarInner).y;
		sidebarBuffer.style.height = `${sidebarInnerTop - contentStickyTop}px`;

		if (flag) {
			// scroll up
			sidebarInner.style.top = "";
			sidebarInner.style.bottom = `${
				-headerHeight -
				gap +
				window.innerHeight -
				sidebarInner.getBoundingClientRect().height
			}px`;
		} else {
			// scroll down
			sidebarInner.style.bottom = "";
			sidebarInner.style.top = `${
				Math.min(
					window.innerHeight - sidebarInner.getBoundingClientRect().height,
					headerHeight
				) - gap
			}px`;
		}
		flagHeight =
			sidebarInner.getBoundingClientRect().height >
			window.innerHeight - headerHeight;
	}

	function resetSticky() {
		sidebarInner.style.bottom = "";
		sidebarInner.style.top = `${headerHeight + gap}px`;
		sidebarBuffer.style.height = "0";
	}

	if (!flagHeight) {
		resetSticky();
	} else {
		calcHeightOfSidebar(false);
	}

	window.addEventListener("scroll", function () {
		let st = window.pageYOffset || document.documentElement.scrollTop;

		if (!flagHeight) {
			resetSticky();
		}

		if (st > lastScrollTop && !flag) {
			// scroll down
			calcHeightOfSidebar(flag);
			flag = true;
		} else if (st < lastScrollTop && flag) {
			// scroll up
			calcHeightOfSidebar(flag);
			flag = false;
		}
		lastScrollTop = st <= 0 ? 0 : st;
	});

	window.addEventListener("resize", function (e) {
		if (!flagHeight) {
			resetSticky();
		}

		calcHeightOfSidebar(true);
		calcHeightOfSidebar(false);
	});
}

const scrollEvents = () => {
	const trueScrollTarget = document.getElementsByClassName("scroll-target")[0];

	if (trueScrollTarget) {
		const scrollTarget = document.querySelectorAll(".scroll-target");
		let threshold = 0.5;
		let decorsArr = [];

		if (document.getElementsByClassName("scroll-decor")[0]) {
			decorsArr = document.querySelectorAll(".scroll-decor");
		}

		if ("IntersectionObserver" in window) {
			console.log("IntersectionObserver is supported");
			const observer = new IntersectionObserver(
				(entries) => {
					entries.forEach((entry, i) => {
						if (entry.isIntersecting) {
							if (decorsArr.length !== 0) {
								decorsArr.forEach((elem, i) => {
									if (entry.target === elem) {
										elem.classList.add("animation");
									}
								});
							}
						}
					});
				},
				{
					threshold: 0,
					rootMargin: "0% 0% -5% 0%",
				}
			);

			scrollTarget.forEach((target, index) => {
				observer.observe(target);
			});
		} else {
			console.log("IntersectionObserver doesn't supported!");
		}
	}
};

scrollEvents();

const counter = document.getElementsByClassName("counter")[0];
if (counter) {
	const counterArr = document.querySelectorAll(".counter");
	for (let i = 0; i < counterArr.length; i++) {
		const counter = counterArr[i].querySelector(".counter");
		const counterInput = counterArr[i].querySelector(".counter input");
		const btnPlus = counterArr[i].querySelector(".counter-btn-plus");
		const btnMinus = counterArr[i].querySelector(".counter-btn-minus");

		let inputValue = +counterInput.value;

		if (counterInput.value === "") {
			counterInput.value = 1;
			inputValue = 1;
		}

		function recalculateCart() {
			counterInput.value = inputValue;
		}

		// onclick Plus button
		btnPlus.addEventListener("click", () => {
			inputValue >= 20 ? (inputValue = 20) : (inputValue += 1);
			recalculateCart();
		});

		// onclick Minus button
		btnMinus.addEventListener("click", () => {
			if (inputValue <= 1) {
				inputValue = 1;
			} else {
				inputValue -= 1;
			}
			recalculateCart();
		});
	}
}

const jobCard = document.getElementsByClassName("job-card")[0];
if (jobCard) {
	if(document.querySelector('.main').classList.contains('logged')) {
		const jobCardArr = document.querySelectorAll(".job-card");
		jobCardArr.forEach((card, i) => {
			const favoriteBtn = card.querySelector(".job-card__favorite-btn");
			favoriteBtn.addEventListener("click", (e) => {
				e.preventDefault();
				favoriteBtn.classList.toggle("active");
			});
		});
	}

}

const jobInfoCard = document.getElementsByClassName("job-info-card")[0];
if (jobInfoCard) {
	if(document.querySelector('.main').classList.contains('logged')) {
		const jobInfoCardArr = document.querySelectorAll(".job-info-card");
		jobInfoCardArr.forEach((card, i) => {
			const favoriteBtn = card.querySelector(".job-info-card__favorite-btn");
			favoriteBtn.addEventListener("click", (e) => {
				e.preventDefault();
				favoriteBtn.classList.toggle("active");
			});
		});
	}

}

const portal = document.getElementsByClassName("portal")[0];
if (portal) {
	const portalWrap = portal.querySelector(".portal__wrap");
	const portalMenu = portal.querySelector(".portal-header-burger");
	const portalBgClose = portal.querySelector(".portal-close-bg");

	portalMenu.addEventListener("click", () => {
		portalMenu.classList.toggle("active");
		portalWrap.classList.toggle("active");
	});

	portalBgClose.addEventListener("click", () => {
		portalMenu.classList.toggle("active");
		portalWrap.classList.toggle("active");
	});
}

const telInputs = document.querySelectorAll('input[type="tel"]');

function handleTelInput(event) {
	const allowedCharacters = ['(', ')', '-', '+', ' ', '0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
	const input = event.target;
	const inputValue = input.value;
	const sanitizedValue = [];

	for (let i = 0; i < inputValue.length; i++) {
		const char = inputValue[i];
		if (allowedCharacters.includes(char)) {
			sanitizedValue.push(char);
		}
	}

	input.value = sanitizedValue.join('');
}

if(telInputs.length > 0) {
	telInputs.forEach(input => {
		input.addEventListener('input', handleTelInput);
	});
}

function isSafari() {
	return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
}
if (isSafari()) {
	const mainElement = document.getElementsByClassName('main')[0];
	const footerElement = document.getElementsByClassName('footer')[0];
	mainElement.classList.add('is-safari');

	if (footerElement) {
		footerElement.classList.add('is-safari');
	}
}


window.updateLogic = function updateLogic() {
	selectsLogic();
	alertClear();
}

const videoPlayer = document.getElementsByClassName('video-player')[0];
if (videoPlayer) {
	const videoPlayersArr = document.querySelectorAll('.video-player');
	for (let i = 0; i < videoPlayersArr.length; i++) {
		const parentEl = videoPlayersArr[i].parentElement;
		let ratio = null;

		const player = new Plyr(videoPlayersArr[i], {
			controls: [
				'play-large',
				'play',
				'progress',
				'current-time',
				'mute',
				'volume',
				'captions',
				'settings',
				'fullscreen',
				'pip',
				'airplay',
			],
			ratio: ratio,
			tooltips: { controls: !0 },
			fullscreen: { iosNative: true },
		});

		player.on('enterfullscreen', function () {
			console.log('enterfullscreen');
		});

		player.on('exitfullscreen', function () {
			console.log('exitfullscreen');
		});

		player.on('play', function () {
			parentEl.classList.add('is-played');
		});

		player.on('pause', function () {
			parentEl.classList.remove('is-played');
		});

		// Expose player so it can be used from the console
		window.player = player;

		if (window.innerWidth >= 1024) {
			setTimeout(function () {
				const plyrArr = document.querySelectorAll('.plyr');
				for (let j = 0; j < plyrArr.length; j++) {
					plyrArr[i].classList.add('plyr-hide-controls');
				}

				plyrArr[i].addEventListener('mouseenter', () => {
					plyrArr[i].classList.remove('plyr-hide-controls');
				});

				plyrArr[i].addEventListener('mouseleave', () => {
					plyrArr[i].classList.add('plyr-hide-controls');
				});
			}, 300);
		}
	}
}

const firstEmployers = document.querySelector('.first-section.employers-page');
if(firstEmployers) {
	const firstFixedBtn = document.querySelector('.first-section__btn-post:not(.first-section__btn-post--second)');

	function handleScroll() {
		const btnRect = firstFixedBtn.getBoundingClientRect();
		const employersRect = firstEmployers.getBoundingClientRect();

		if (btnRect.bottom < employersRect.bottom) {
			firstFixedBtn.classList.add('is-small');
		} else {
			firstFixedBtn.classList.remove('is-small');
		}

		firstFixedBtn.classList.remove('opacity-0');
	}

	handleScroll();

	window.addEventListener('scroll', handleScroll);
}

window.counterInputsFunc = function counterInputsFunc() {
	const checkoutInputsArr = document.querySelectorAll('.checkout-input');

	checkoutInputsArr.forEach((input) => {
		input.addEventListener('input', function (event) {
			let value = this.value.trim();

			value = value.replace(/\D/g, '');

			if (parseInt(value) > 999) {
				value = '999';
			}

			this.value = value;
		});
	});
};
counterInputsFunc();

window.decreaseInputNumber = function decreaseInputNumber(inputId) {
	const input = document.getElementById(inputId);
	input.stepDown();

	const parentBlock = input.closest('.counter-parent-toggle');
	if ((Number(input.value) === 0 || !input.value) && (parentBlock && parentBlock.classList.contains('active'))) {
		parentBlock.classList.remove('active');
	}

	const event = new Event('change');
	input.dispatchEvent(event);
};

window.increaseInputNumber = function increaseInputNumber(inputId) {
	const input = document.getElementById(inputId);
	input.stepUp();

	const event = new Event('change');
	input.dispatchEvent(event);

	let value = input.value.trim();

	value = value.replace(/\D/g, '');

	if (parseInt(value) > 999) {
		value = '999';
	}

	input.value = value;
};

var elements = document.querySelectorAll(".force-sticky");
Stickyfill.forceSticky();
Stickyfill.add(elements);
